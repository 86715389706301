import * as THREE from "@/assets/three/build/three.module.js";
import * as Tween from 'gsap';


var Commen = {
	//创建字体精灵 
	makeTextSprite: function (message, parameters) {

		if (parameters === undefined) parameters = {};

		var fontface = parameters.hasOwnProperty("fontface") ?
			parameters["fontface"] : "STHeiti Light";

		/* 字体大小 */
		var fontsize = parameters.hasOwnProperty("fontsize") ?
			parameters["fontsize"] : 60;

		/* 边框厚度 */
		var borderThickness = parameters.hasOwnProperty("borderThickness") ?
			parameters["borderThickness"] : 2;

		/* 边框颜色 */
		var borderColor = parameters.hasOwnProperty("borderColor") ?
			parameters["borderColor"] : { r: 0, g: 0, b: 0, a: 1.0 };

		/* 背景颜色 */
		var backgroundColor = parameters.hasOwnProperty("backgroundColor") ?
			parameters["backgroundColor"] : { r: 255, g: 255, b: 255, a: 1 };



		/* 创建画布 */
		var canvas = document.createElement('canvas');

		let canvasHeight = fontsize * 1;
		let canvasWidth = canvasHeight * this.strLen(message);

		canvas.width = canvasWidth
		canvas.height = canvasHeight
		// canvas.style.backgroundColor = 'gray'
		var context = canvas.getContext('2d');
		

		/* 字体加粗 */
		context.font = "" + fontsize + "px " + fontface;

		/* 获取文字的大小数据，高度取决于文字的大小 */
		// var metrics = context.measureText(message);

		context.textAlign  = 'left';
		context.textBaseline = 'hanging'
		/* 背景颜色 */
		context.fillStyle = "rgba(" + backgroundColor.r + "," + backgroundColor.g + "," +
			backgroundColor.b + "," + backgroundColor.a + ")";

		/* 边框的颜色 */
		context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + "," +
			borderColor.b + "," + borderColor.a + ")";
		context.lineWidth = borderThickness;

		/* 绘制圆角矩形 */
		//roundRect(context, borderThickness/2, borderThickness/2, textWidth + borderThickness, fontsize * 1.2 + borderThickness, 8); 

		/* 字体颜色 */
		// context.fillStyle = "rgba(255, 255, 255, 1.0)";
		context.fillText(message, 0, 0, canvasWidth);
		// context.fillText( message, 150-textWidth/2,55+fontsize/2);

		/* 画布内容用于纹理贴图 */
		var texture = new THREE.Texture(canvas);
		texture.needsUpdate = true;

		var spriteMaterial = new THREE.SpriteMaterial({
			map: texture,
			// depthWrite:false,
			// transparent: false, 
			opacity:1
		});
		let sprite = new THREE.Sprite(spriteMaterial);

		//console.log(sprite.spriteMaterial);

		/* 缩放比例 */
		//sprite.scale.set(30,15,0);

		return sprite;

	},

	
	makeTextTexture: function (message, parameters) {

		if (parameters === undefined) parameters = {};

		var fontface = parameters.hasOwnProperty("fontface") ?
			parameters["fontface"] : "STHeiti Light";

		/* 字体大小 */
		var fontsize = parameters.hasOwnProperty("fontsize") ?
			parameters["fontsize"] : 60;

		/* 边框厚度 */
		var borderThickness = parameters.hasOwnProperty("borderThickness") ?
			parameters["borderThickness"] : 2;

		/* 边框颜色 */
		var borderColor = parameters.hasOwnProperty("borderColor") ?
			parameters["borderColor"] : { r: 0, g: 0, b: 0, a: 1.0 };

		/* 背景颜色 */
		var backgroundColor = parameters.hasOwnProperty("backgroundColor") ?
			parameters["backgroundColor"] : { r: 255, g: 255, b: 255, a: 1 };



		/* 创建画布 */
		var canvas = document.createElement('canvas');

		let canvasHeight = fontsize * 1;
		let canvasWidth = canvasHeight * this.strLen(message);

		canvas.width = canvasWidth
		canvas.height = canvasHeight
		// canvas.style.backgroundColor = 'gray'
		var context = canvas.getContext('2d');


		/* 字体加粗 */
		context.font = "" + fontsize + "px " + fontface;

		/* 获取文字的大小数据，高度取决于文字的大小 */
		// var metrics = context.measureText(message);

		context.textAlign = 'left';
		context.textBaseline = 'hanging'
		/* 背景颜色 */
		context.fillStyle = "rgba(" + backgroundColor.r + "," + backgroundColor.g + "," +
			backgroundColor.b + "," + backgroundColor.a + ")";

		/* 边框的颜色 */
		context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + "," +
			borderColor.b + "," + borderColor.a + ")";
		context.lineWidth = borderThickness;

		/* 绘制圆角矩形 */
		//roundRect(context, borderThickness/2, borderThickness/2, textWidth + borderThickness, fontsize * 1.2 + borderThickness, 8); 

		/* 字体颜色 */
		// context.fillStyle = "rgba(255, 255, 255, 1.0)";
		context.fillText(message, 0, 0, canvasWidth);
		// context.fillText( message, 150-textWidth/2,55+fontsize/2);

		/* 画布内容用于纹理贴图 */
		var texture = new THREE.Texture(canvas);
		texture.needsUpdate = true;

		
		return texture;

	},
	
	//绘制圆角矩形 
	roundRect: function (ctx, x, y, w, h, r) {
		ctx.beginPath();
		ctx.moveTo(x + r, y);
		ctx.lineTo(x + w - r, y);
		ctx.quadraticCurveTo(x + w, y, x + w, y + r);
		ctx.lineTo(x + w, y + h - r);
		ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
		ctx.lineTo(x + r, y + h);
		ctx.quadraticCurveTo(x, y + h, x, y + h - r);
		ctx.lineTo(x, y + r);
		ctx.quadraticCurveTo(x, y, x + r, y);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

	},
	//获取字长度

	strLen: function (str) {
		var len = 0;
		for (var i = 0; i < str.length; i++) {
			if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
				len += 1;
			} else {
				len += 0.5;
			}
		}
		return len;
	},
	//获取字大小比例
	get_bili: function (num) {
		let res = 60;
		if (num <= 3) {
			res = 80;
		} else if (3 < num && num <= 4) {
			res = 60;
		} else if (4 < num && num <= 5) {
			res = 40;
		} else if (5 < num && num <= 6) {
			res = 30;
		} else {
			res = 20;
		}

		return res;

	},
	//获取字大小比例
	get_bili_title: function (num) {
		let res = 35;
		if (num <= 6) {
			res = 35;
		} else if (6 < num && num <= 7) {
			res = 32;
		} else if (7 < num && num <= 8) {
			res = 29;
		} else if (8 < num && num <= 9) {
			res = 26;
		} else {
			res = 25;
		}

		return res;

	},
	TextureAnimator: function (texture, tilesHoriz, tilesVert, numTiles, tileDispDuration) {
		// note: texture passed by reference, will be updated by the update function.

		this.tilesHorizontal = tilesHoriz;
		this.tilesVertical = tilesVert;
		// how many images does this spritesheet contain?
		//  usually equals tilesHoriz * tilesVert, but not necessarily,
		//  if there at blank tiles at the bottom of the spritesheet. 
		this.numberOfTiles = numTiles;
		texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
		texture.repeat.set(1 / this.tilesHorizontal, 1 / this.tilesVertical);

		// how long should each image be displayed?
		this.tileDisplayDuration = tileDispDuration;

		// how long has the current image been displayed?
		this.currentDisplayTime = 0;

		// which image is currently being displayed?
		this.currentTile = 0;

		this.update = function (milliSec) {
			this.currentDisplayTime += milliSec;
			while (this.currentDisplayTime > this.tileDisplayDuration) {
				this.currentDisplayTime -= this.tileDisplayDuration;
				this.currentTile++;
				if (this.currentTile == this.numberOfTiles)
					this.currentTile = 0;
				var currentColumn = this.currentTile % this.tilesHorizontal;
				texture.offset.x = currentColumn / this.tilesHorizontal;
				var currentRow = Math.floor(this.currentTile / this.tilesHorizontal);
				texture.offset.y = currentRow / this.tilesVertical;
			}
		};
	},
	go_camera: function (tx, ty, tz, px, py, pz, control, camera, time = 1, delaytime = 0) {
		
		if(this.got1 && this.got2){
			this.got1.kill();
			this.got2.kill();
		}

		return new Promise((res, rej) => {
			this.got1 = Tween.TweenMax.to(
				control.target,
				time, //动画时间
				{ x: tx, y: ty, z: tz, delay: delaytime, ease: Tween.Power2.easeInOut }
			);
			this.got2 = Tween.TweenMax.to(
				camera.position,
				time, //动画时间
				{
					x: px, y: py, z: pz, delay: delaytime, ease: Tween.Power2.easeInOut,
					onComplete: () => {
						res(null);
					}
				}
			);
		})
	},

	go_cameraV: function (target, position, control, camera, time, delaytime) {

		if(this.got1 && this.got2){
			this.got1.kill();
			this.got2.kill();
		}
		return new Promise((res, rej) => {
			var { x, y, z } = target;
			this.got1 = Tween.TweenMax.to(
				control.target,
				time, //动画时间
				{ x: x, y: y, z: z, delay: delaytime, ease: Tween.Power2.easeInOut }
			);
			var { x, y, z } = position;

			this.got2 = Tween.TweenMax.to(
				camera.position,
				time, //动画时间
				{
					x: x, y: y, z: z,
					delay: delaytime, ease: Tween.Power2.easeInOut,
					onComplete: res
				}
			);

			// console.log(this.got1, this.got2);
		})
	}
}
export default Commen;



