var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{attrs:{"id":"mse"}},[_c('video',{ref:"myVideo",attrs:{"id":"video","loop":"","webkit-playsinline":"","playsinline":"","preload":"auto","x-webkit-airplay":"true","x5-playsinline":"true","x5-video-player-type":"h5"}},[_c('source',{attrs:{"src":_vm.url1,"type":"video/mp4"}})]),_c('div',{staticClass:"jiundutiao"})]),(!_vm.drawershow)?_c('div',{staticClass:"playbtn",on:{"click":function($event){return _vm.btn()}}},[_c('img',{attrs:{"src":_vm.videostate ? _vm.zanting : _vm.bofang,"alt":""}})]):_vm._e(),_c('div',{staticClass:"home_center"},[(!_vm.drawershow)?_c('div',{staticClass:"top_text"},[_c('div',{staticClass:"top_text_son"},[_c('a',{attrs:{"href":"https://testnet.myweber.org/"}},[_vm._v(_vm._s(_vm.language ? "浏览器" : "Explorer"))])]),_c('div',{staticClass:"top_text_son"},[_c('a',[_vm._v(_vm._s(_vm.language ? "提供" : "Provide"))])]),_c('div',{staticClass:"top_text_son"},[_c('a',[_vm._v(_vm._s(_vm.language ? "开发" : "Build"))])]),_c('div',{staticClass:"top_text_son"},[_c('a',[_vm._v(_vm._s(_vm.language ? "博客" : "Blog"))])])]):_vm._e(),(!_vm.drawershow)?_c('div',{staticClass:"top_yuyan",on:{"click":function($event){return _vm.btnclick()}}},[_c('p',[_vm._v("EN")]),_c('p',[_vm._v("中文")])]):_vm._e(),(_vm.drawershow)?_c('div',{staticClass:"yuyan"},[_c('img',{attrs:{"src":_vm.xuanzhong == '第一章' || _vm.xuanzhong == '第三章'
            ? _vm.xuanxiang2
            : _vm.xuanxiang,"alt":""},on:{"click":function($event){return _vm.unfold2()}}})]):_vm._e(),_c('el-drawer',{attrs:{"visible":_vm.drawer,"direction":_vm.direction,"modal-append-to-body":false,"wrapperClosable":true},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"drawer_title"},[_c('p',[_vm._v(_vm._s(_vm.language ? "存储" : "Store"))])])])],1),_c('div',{staticClass:"bottom_box"},[_c('div',{staticClass:"bottom_box_top"},[_c('p',{attrs:{"id":"text_animation"}},[_vm._v(" "+_vm._s(_vm.language ? "在过去300年的工业革命里，技术和科学的进步产生了大量的工具。" : "Over the past 300 years of the Industrial Revolution, technological and scientific advances have produced avast array of tools")+" ")])]),_c('div',{staticClass:"bottom_box_bottom"},[_vm._l((_vm.list),function(item,index){return (!_vm.drawershow)?_c('div',{key:index,class:_vm.xuanzhong == item.name
            ? 'bottom_box_bottom_sonopt'
            : 'bottom_box_bottom_son',on:{"click":function($event){return _vm.switchover(item.name)}}},[_c('p',[_vm._v(_vm._s(_vm.language ? item.name : item.enname))]),_c('p',[_vm._v(_vm._s(_vm.language ? item.text : item.entext))])]):_vm._e()}),_vm._l((_vm.list),function(item,index){return (_vm.drawershow && _vm.xuanzhong == item.name)?_c('div',{key:index,class:_vm.xuanzhong == item.name
            ? 'bottom_box_bottom_sonopt'
            : 'bottom_box_bottom_son',attrs:{"id":item.id}},[_c('p',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.text))])]):_vm._e()}),(_vm.drawershow)?_c('div',{staticClass:"jiantou"},[_c('img',{attrs:{"src":require("../assets/image/zuojiantou1.png"),"alt":""},on:{"click":function($event){return _vm.zuo()}}}),_c('img',{attrs:{"src":require("../assets/image/youjiantou1.png"),"alt":""},on:{"click":function($event){return _vm.you()}}})]):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }